<template>
  <b-table
    hover
    :busy="busy"
    :items="items"
    :fields="fieldsList"
    responsive
    :sticky-header="'calc(100vh - 330px)'"
  >
    <!-- Column: Name -->
    <template #cell(name)="{ item }">
      <b-media
        class="d-flex align-items-center ml-50 mt-25"
        style="min-width: 200px"
      >
        <template>
          <b-link
            :href="getCollectiveUrl(item.community)"
            target="_blank"
            class="text-dark d-flex align-items-center"
          >
            <div class="mb-75 d-flex align-items-center w-100">
              <collective-logo :logo="item.community.logoURL" :size="30" />
              <div class="ml-50">
                <p class="mb-0 font-weight-bold">
                  {{ translate(item.community.name) }}
                </p>
                <p
                  v-if="item.community.headline"
                  class="small mb-0 mt-n25 text-muted"
                >
                  {{ translate(item.community.headline) }}
                </p>
              </div>
            </div>
          </b-link>
        </template>
      </b-media>
    </template>
    <!-- Column: type -->
    <template #cell(type)="{ item }">
      <div
        class="w-100 h-100 d-flex pl-1 ml-50 align-items-center"
        style="min-width: 120px"
      >
        <b-badge
          pill
          :variant="getColorSpace(item.community.mainType)"
          class="text-capitalize mb-0 mt-75 check-button"
        >
          {{ getStatusSpace(item.community.mainType) }}
        </b-badge>
      </div>
    </template>
    <!-- Column: Date -->
    <template #cell(createdAt)="{ item }">
      <div
        class="w-100 h-100 d-flex pl-75 mr-25 ml-50 align-items-center"
        style="min-width: 160px"
      >
        <p class="mb-0">
          {{ timestampToTime(item.createdAt) }}
        </p>
      </div>
    </template>
    <!-- Column: Status -->
    <template #cell(status)="{ item }">
      <div class="d-flex align-items-center" style="min-width: 120px">
        <b-badge
          v-if="item.status"
          pill
          :variant="getColor(item.status)"
          class="text-capitalize mb-0 ml-1 mt-75 check-button"
        >
          {{ getStatus(item.status) }}
        </b-badge>
        <p v-else class="text-muted mb-0 ml-2 pl-75 mt-75">---</p>
      </div>
    </template>
    <!-- Column: price -->
    <template #cell(price)="{ item }">
      <div
        class="w-100 h-100 d-flex align-items-center justify-content-end"
        style="min-width: 120px"
      >
        <p class="mb-0">{{ item.price }} {{ item.currency.symbol }}</p>
      </div>
    </template>
    <!-- Column: Real -->
    <template #cell(real)="{ item }">
      <div
        class="w-100 h-100 d-flex align-items-center justify-content-end"
        style="min-width: 120px"
      >
        <p v-if="item.hasDiscount" class="mb-0">
          {{ item.price + item.discountAbsolute }}{{ item.currency.symbol }}
        </p>
        <p v-else>---</p>
      </div>
    </template>
    <!-- Column: Descuento -->
    <template #cell(discount)="{ item }">
      <div
        class="w-100 h-100 d-flex align-items-center justify-content-end"
        style="min-width: 120px"
      >
        <p v-if="item.hasDiscount" class="mb-0">
          - {{ item.discountAbsolute }}{{ item.currency.symbol }}
        </p>
        <p v-else class="mb-0">---</p>
      </div>
    </template>
    <!-- Column: ref -->
    <template #cell(ref)="{ item }">
      <div
        class="w-100 h-100 d-flex pl-1 ml-50 align-items-center"
        style="min-width: 120px"
      >
        <p>{{ item.ref }}</p>
      </div>
    </template>

    <template #custom-foot="{ columns }">
      <b-td v-for="columnIndex in columns" :key="columnIndex" class="totals">
        <p
          v-if="columnIndex === 1"
          class="font-weight-extrabold mb-0 font-medium-2 ml-2 text-white"
        >
          {{ $t('backoffice.payments.table.totals') }}
        </p>
        <div
          v-if="[3, 4, 5].includes(columnIndex)"
          class="w-100 h-100 d-flex align-items-center justify-content-end"
        >
          <p v-if="columnIndex === 3" class="mb-0 text-white">
            {{ totals.price.toFixed(2) }} {{ items[0].currency.symbol }}
          </p>
          <p v-if="columnIndex === 4" class="mb-0 text-muted">
            {{ totals.real.toFixed(2) }} {{ items[0].currency.symbol }}
          </p>
          <p v-if="columnIndex === 5" class="mb-0 text-muted">
            - {{ totals.discount.toFixed(2) }}
            {{ items[0].currency.symbol }}
          </p>
        </div>
      </b-td>
    </template>
  </b-table>
</template>

<script>
import { translateTranslationTable } from "@/@core/libs/i18n/utils";
import moment from "moment";
import CollectiveLogo from "@core/components/collective-logo/CollectiveLogo.vue";
import { getCollectiveUrl } from "@core/utils/collective";

export default {
  name: "MyPaymentsTableWeb",
  components: {
    CollectiveLogo,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    busy: {
      type: Boolean,
      required: true,
    },
    totals: {
      type: Object,
      required: true,
    },
  },
  computed: {
    currentCollective() {
      return this.$store.getters.currentCollective;
    },
    fieldsList() {
      return [
        {
          key: "name",
          label: this.$t('backoffice.payments.table.header.name'),
          sortable: true,
        },
        {
          key: "type",
          label: this.$t('backoffice.payments.table.header.space-type'),
          sortable: true,
        },
        {
          key: "price",
          label: this.$t('backoffice.payments.table.header.total'),
          sortable: true,
        },
        {
          key: "real",
          label: this.$t('backoffice.payments.table.header.initial-price'),
          sortable: true,
        },
        {
          key: "discount",
          label: this.$t('backoffice.payments.table.header.discount'),
          sortable: true,
        },
        {
          key: "ref",
          label: this.$t('backoffice.payments.table.header.reference'),
          sortable: true,
        },
        {
          key: "status",
          label: this.$t('backoffice.payments.table.header.status'),
          sortable: true,
        },
        {
          key: "createdAt",
          label: this.$t('backoffice.payments.table.header.created-at'),
          sortable: true,
        },
      ];
    },
  },
  methods: {
    async fetchMembers(member) {
      const response = await this.$store.dispatch("getItems", {
        itemType: "communityMembers",
        forceAPICall: true,
        requestConfig: {
          page: 1,
          perPage: 10,
          username: member.username,
        },
      });

      if (response.data?.length > 0) {
        const route = this.$router.resolve({
          name: "backoffice-members-details",
          params: {
            username: member.username,
            communityId: this.currentCollective.slug,
          },
        });
        window.open(route.href, "_blank");
        return;
      }
      const route = this.$router.resolve({
        name: "backoffice-members-details",
        params: {
          username: member.username,
          communityId: this.currentCollective.parentCollective.slug,
        },
      });
      window.open(route.href, "_blank");
    },
    translate(field) {
      return translateTranslationTable(
        this.$store.state.locale.currentLocale,
        field
      );
    },
    timestampToTime(timestamp) {
      moment.locale(this.currentLocale);
      return moment(timestamp).format("DD MMM YYYY - HH:mm");
    },
    getStatus(payment) {
      const statusPayment = {
        pending: this.$t("backoffice.members.pending"),
        succeeded: this.$t("backoffice.members.confirmed"),
      };
      return statusPayment[payment];
    },
    getColor(payment) {
      const color = {
        pending: "light",
        succeeded: "light-success",
      };
      return color[payment];
    },
    getCollectiveUrl(collective) {
      return `${getCollectiveUrl(collective)}/welcome`;
    },
    getStatusSpace(mainType) {
      // console.log('mainType', mainType);
      if(!mainType){
        return 'Space';
      }
      const type = {
        Community: this.$t("collectives.items.Community"),
        Subcommunity: this.$t("collectives.items.Subcommunity"),
        Event: this.$t("collectives.items.Event"),
        Section: this.$t("collectives.items.Section"),
        Course: this.$t("collectives.items.Course"),
        Workspace: this.$t("collectives.items.Workspace"),
        Challenge: this.$t("collectives.items.Challenge"),
        Organization: this.$t("collectives.items.Organization"),
        Service: this.$t("collectives.items.Service"),
      };
      return type[mainType][0].toUpperCase() + type[mainType].substring(1);
    },
    getColorSpace(payment) {
      const color = {
        0: "light",
        1: "light-warning",
        2: "warning",
        3: "light-success",
        4: "light-danger",
        5: "light-dark",
        6: "secondary",
        7: "light-info",
        8: "info",
        81: "dark",
        Community: "primary",
        Subcommunity: "light-info",
        Event: "danger",
        Section: "success",
        Course: "info",
        Workspace: "dark",
        Challenge: "secondary",
        Organization: "light-info",
        Service: "light-warning",
      };
      return color[payment];
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-include"; // Bootstrap includes
.totals {
  background-color: $primary;
}
</style>
